<template>
	<div>
		<div class="person-info">
			<div class="form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item :label="$t('businessname')" prop="title">
						<el-input v-model="ruleForm.title" :placeholder="$t('businesscontent')"></el-input>
				 </el-form-item>
					<el-form-item class="enterprise-logo" :label="$t('businesslogo')" prop="otherMaterials">
						<div class="coverup">
							<el-upload :action="uploadImageUrl" list-type="picture-card" :on-success="callbackLicense"
								:limit="1" :before-upload="testformatLicense" name="image" ref="uploadLicense">
							  <!-- <img v-if="ruleForm.otherMaterials" :src="ruleForm.otherMaterials" class="avatar"> -->
							  <i slot="default" class="el-icon-plus logo-uploader-icon"></i>
							  <div slot="file" slot-scope="{ file }">
							  	<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
							  	<span class="el-upload-list__item-actions">
							  		<span v-if="!disabled" class="el-upload-list__item-delete"
							  			@click="handleRemoveLicense(file)">
							  			<i class="el-icon-delete"></i>
							  		</span>
							  	</span>
							  </div>
							  <!-- <i v-else class="el-icon-plus logo-uploader-icon"></i> -->
							</el-upload>
						</div>
						
						<div class="el-upload__tip">{{$t('limits')}}</div>
					</el-form-item>
					<el-form-item :label="$t('businesslicense')" prop="businessLicense">
						<!-- <el-upload
						    class="avatar-uploader"
						    action="https://jsonplaceholder.typicode.com/posts/"
						    :show-file-list="false"
						    :on-success="handleAvatarSuccess"
						    :before-upload="beforeAvatarUpload"> -->
						<div class="logoup">
							<el-upload :action="uploadImageUrl" class="avatar-uploader" list-type="picture-card" :on-success="callbackLogo"
								:limit="1" :before-upload="testformatLogo" name="image" ref="uploadLogo">
							  <!-- <img v-if="ruleForm.businessLicense" :src="ruleForm.businessLicense" class="avatar"> -->
							  <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
							  <i slot="default" class="el-icon-plus avatar-uploader-icon"></i>
							  <div slot="file" slot-scope="{ file }">
							  	<img class="el-upload-list__item-thumbnail img" :src="file.url" alt="" />
							  	<span class="el-upload-list__item-actions">
							  		<span v-if="!disabled1" class="el-upload-list__item-delete"
							  			@click="handleRemoveLogo(file)">
							  			<i class="el-icon-delete"></i>
							  		</span>
							  	</span>
							  </div>
							  <!-- <div class="el-upload__tip">身份证/护照（正面）</div> -->
							</el-upload>	
						</div>
						
					</el-form-item>
					<el-form-item label="" prop="name">
							<el-checkbox v-model="xieyi">{{ $t("readAndAgree") }}
							</el-checkbox>
							<span @click="showAgreement(2)" style="color: #3875FB; cursor: pointer;">《{{
					  $t('enterpriseAgreement')
					}}》</span>
						</el-form-item>
					<el-form-item class="button-finish">
						<el-button @click="submit" type="primary">{{$t('submitreport')}}</el-button>
					</el-form-item>
				</el-form>
			</div>
			
		</div>
		<div>
			<!-- 协议弹窗 -->
			<AgreementPopup ref="popupAgreement"></AgreementPopup>
		</div>
	</div>
	
</template>

<script>
	import { registerEnterprise } from "@/api/Mine"
	import AgreementPopup from "@/components/popup/AgreementPopup.vue"
	export default {
		props: {},
		computed: {},
		components: {},
		data() {
			return {
				xieyi: false, //协议
				disabled: false,
				disabled1: false,
				idCardImg: '',
				ruleForm: {
					title: '',//企业名称
					// countryArea: '',
					// idCardNum: '',
					businessLicense: '',//营业执照
					otherMaterials: ''//企业LOGO
				},
				imageLogo: '',
				imageLicense: '',
				rules: {},
				myInfo: {},
				uploadImageUrl: process.env.VUE_APP_UPLOAD_IMAGE_URL, //上传图片路径
				agreeType: 2,
			}
		},
		components: {
			AgreementPopup
		},
		created() {
			this.myInfo = JSON.parse(localStorage.getItem('myInfo'))
			this.ruleForm.title = this.myInfo.group_name
		},
		methods: {
			showAgreement(type) {
			  this.agreeType = type
			  this.$refs.popupAgreement.open()
			  console.log('协议',this.agreeType,'2222',type,'1111')
			  this.$refs.popupAgreement.getAgreement(this.agreeType)
			},
			handleAvatarSuccess(){
				
			},
			beforeAvatarUpload(){
				
			},
			// 上传Logo成功
			callbackLogo(res,file) {
				console.log(res, 'rrrrrrrrrr')
				// this.imageUrl = process.env.VUE_APP_FILE_PATH + '/' + res.data.url;
				this.ruleForm.otherMaterials = URL.createObjectURL(file.raw);
				this.imageLogo = res.data.url
				console.log(this.imageLogo)
			},
			// 移除Logo图片
			handleRemoveLicense(file) {
				this.$refs.uploadLicense.clearFiles();
			},
			testformatLogo(file) {
				const cover_isJPG = file.type === "image/jpeg";
				const cover_isPNG = file.type === "image/png";
				const cover_isLt10M = file.size / 1024 / 1024 < 10;
				if (cover_isJPG || cover_isPNG) {
					if (!cover_isLt10M) {
						this.$message({
							type: "error",
							message: this.$t("Collaboration.toobig"),
						});
						return false;
					} else {}
				} else {
					this.$message({
						type: "error",
						message: this.$t("Collaboration.rightcover"),
					});
					return false;
				}
			},
			// 上传营业执照成功
			callbackLicense(res,file) {
				console.log(res, 'rrrrrrrrrr')
				// this.imageUrl = process.env.VUE_APP_FILE_PATH + '/' + res.data.url;
				this.ruleForm.businessLicense = URL.createObjectURL(file.raw);
				this.imageLicense = res.data.url
				console.log(this.imageLicense)
			},
			// 移除Logo图片
			handleRemoveLogo(file) {
				this.$refs.uploadLogo.clearFiles();
			},
			testformatLicense(file) {
				const cover_isJPG = file.type === "image/jpeg";
				const cover_isPNG = file.type === "image/png";
				const cover_isLt10M = file.size / 1024 / 1024 < 10;
				if (cover_isJPG || cover_isPNG) {
					if (!cover_isLt10M) {
						this.$message({
							type: "error",
							message: this.$t("Collaboration.toobig"),
						});
						return false;
					} else {}
				} else {
					this.$message({
						type: "error",
						message: this.$t("Collaboration.rightcover"),
					});
					return false;
				}
			},
			// 提交审核
			submit(){
				if(this.ruleForm.title == ''){
					this.$message({
						type: "error",
						message: this.$t("enterpriseTitleNotNull"),
					});
					return false
				}
				if(this.imageLogo == ''){
					this.$message({
						type: "error",
						message: this.$t("businesslicenseNotNull"),
					});
					return false
				}
				if(this.imageLicense == ''){
					this.$message({
						type: "error",
						message: this.$t("enterpriseLogoNotNull"),
					});
					return false
				}
				if (this.xieyi === false) {
					this.$message({
						type: "error",
						message: this.$t("pleaseCheckEnterpriseAgree"),
					});
					return false
				} 
				registerEnterprise({
						title: this.ruleForm.title,//企业名称
						businessLicense: this.imageLogo,//营业执照
						otherMaterials: this.imageLicense//企业LOGO this.ruleForm
				}).then(res => {
				  // this.$message.success(res.msg)
				  console.log('groupMusic:', res)
				  this.$router.push({
				  	path: '/personalCenter/accountManagement/certificationSucceeded'
				  })
				  // this.getMyInfoObj();
				  // this.goLogin()
				}).catch(err => {
				  console.log('注册失败:', err)
				})
				
				
			}
		}
	}
</script>

<style lang="less" scoped>
	/deep/.el-upload__input {
		display: none;
	}
	
	/deep/.el-upload {
		width: 80px;
		height: 80px;
		position: relative;
	}
	
	/deep/.el-icon-plus {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	
	}
	
	/deep/ .coverup .el-upload-list__item-thumbnail {
		display: inline-block;
		width: 80px;
		height: 80px;
	}
	
	/deep/.el-upload-list--picture-card>.el-upload-list__item-actions {
		width: 80px;
		height: 80px;
	}
	
	/deep/.is-ready {
		width: 80px;
		height: 80px;
	}
	
	/deep/.el-upload-list--text>.el-upload-list__item {
		// width: 300px;
		height: 30px;
		position: relative;
		// top: -10px;
	}
	
	/deep/.el-upload-list--text {
		clear: both;
	}
	
	.upload-ar>div:nth-child(1) {
		float: left;
	}
	
	.upload-ar>div:nth-child(2) {
		float: right;
		margin-right: 300px;
	}
	
	/deep/.el-upload--text {
		height: 50px;
	}
	
	/deep/.coverup .is-success {
		width: 80px;
		height: 80px;
	}
	/deep/.logoup .is-success {
		width: 170px;
		height: 100px;
		margin-top: 0px !important;
		// margin: 0 !important;
	}
	.coverup .img {
		width: 80px;
		height: 80px;
	}
	.logoup .img {
		width: 170px;
		height: 100px;
	}
	::v-deep .logoup .el-upload-list__item{
		margin-top: 0px !important;
	}
	::v-deep .logoup .el-upload-list--picture-card{
		margin-top: 8px !important;
		display: flex;
		align-items: center;
	}
	
	// ::v-deep .avatar-uploader .el-upload {
	// 	background: @recommendMusicianListBGColor;
	// }
	
	::v-deep .coverup .el-upload .el-upload--picture-card {
		background: @recommendMusicianListBGColor;
		display: flex;
		align-items: center;
	}
	
	::v-deep .coverup .el-upload--picture-card {
		background: @recommendMusicianListBGColor;
		width: 80px;
		height: 80px;
	}
	::v-deep .logoup .el-upload .el-upload--picture-card {
		background: @recommendMusicianListBGColor;
		display: flex;
		align-items: center;
	}
	
	::v-deep .logoup .el-upload--picture-card {
		background: @recommendMusicianListBGColor;
		width: 170px;
		height: 100px;
	}
	.person-info {
		width: 100%;
		height: 650px;
		background: @recommendMusicianListBGColor;
		border-radius: 6px;
		backdrop-filter: blur(50px);
		margin-top: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		// justify-content: center;
		padding-top: 30px;
		.avatar{
			// width: 100%;
			margin-bottom: 40px;
			display: flex;
			flex-direction: column;
			// justify-content: center;
			align-items: center;
			.avatar-img {
				width: 120px;
				height: 120px;
			}
			.avatar-name{
				height: 22px;
				font-size: 16px;
				font-weight: 500;
				color: @recommendMusicianListTextColor;
				margin-top: 16px;
			}
		}
		.form {
			* {
				::-webkit-scrollbar {
					/*隐藏滚轮*/
					display: none;
				}
			}
			.demo-ruleForm {
				::v-deep .el-input {
					width: 400px;
					background: @recommendMusicianListBGColor;
					border: none;
				}

				::v-deep .el-form-item__label {
					color: @recommendMusicianListTextColor;
				}

				::v-deep .el-input__inner {
					width: 400px;
					background: rgba(34, 36, 38, 0.0500);
					border: none;
					color: @recommendMusicianListTextColor;
					&:hover {
					  border: 1px solid @popupCancelTextColor;
					}
				}

				::v-deep .el-form-item__label {
					color: @recommendMusicianListTextColor;
				}

				::v-deep .el-button--primary {
					width: 86px;
					height: 38px;
					background: #3370FF;
					border-radius: 4px;
					border: none;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;
					color: #FFFFFF;
				}

				::v-deep .el-input-group__append,
				.el-input-group__prepend {
					background: rgba(0, 0, 0, 0);
					border: none;
				}

				::v-deep .el-textarea {
					width: 400px !important;
					background: @recommendMusicianListBGColor;
				}

				::v-deep .el-textarea__inner {
					width: 400px !important;
					height: 128px;
					background: rgba(34, 36, 38, 0.0500);
					border: none;
					resize: none;
					&:hover {
					  border: 1px solid @popupCancelTextColor;
					}
				}

				::v-deep .el-input__count {
					background: rgba(0, 0, 0, 0);
				}

				.upload{
					.upload-title{
						line-height: 36px;
					}
				}
				.enterprise-logo{
					::v-deep .el-form-item__content{
						display: flex;
						align-items: flex-end;
					}
					// logo上传
					::v-deep .logo-uploader{
						width: 80px;
						height: 80px;
						background: @personInfoFormColor;
						// background: rgba(34,36,38,0.05);
						// border-radius: 4px;
						// border: 1px solid #666666;
					}
					::v-deep .logo-uploader .el-upload {
						width: 80px;
						height: 80px;
						display: flex;
						align-items: center;
						justify-content: center;
					  border: 1px dashed @headerSelectTextColor;
					  border-radius: 6px;
					  cursor: pointer;
					  position: relative;
					  overflow: hidden;
					}
					
					::v-deep .logo-uploader .el-upload:hover {
					  border-color: #409EFF;
					}
					
					.logo-uploader-icon {
					  font-size: 28px;
					  color: @headerSelectTextColor;
					  width: 80x;
					  height: 80px;
					  line-height: 80px;
					  text-align: center;
					  
					
					  &:hover {
					    color: #409EFF;
					  }
					}
					
					// .avatar {
					//   width: 148px;
					//   height: 60px;
					//   display: block;
					// }
					
				}
				
				
				// 上传样式
				::v-deep .avatar-uploader {
				  display: flex;
				  align-items: center;
				  // justify-content: center;
				  // width: 170px;
				  // height: 100px;
				  // background: @personInfoFormColor;
				}
				
				::v-deep .avatar-uploader .el-upload {
					width: 170px;
					height: 100px;
					display: flex;
					align-items: center;
					justify-content: center;
				  border: 1px dashed @headerSelectTextColor;
				  border-radius: 6px;
				  cursor: pointer;
				  position: relative;
				  overflow: hidden;
				}
				
				::v-deep .avatar-uploader .el-upload:hover {
				  border-color: #409EFF;
				}
				
				.avatar-uploader-icon {
				  font-size: 28px;
				  color: @headerSelectTextColor;
				  width: 170px;
				  height: 100px;
				  line-height: 100px;
				  text-align: center;
				
				  &:hover {
				    color: #409EFF;
				  }
				}
				
				.avatar {
				  width: 170px;
				  height: 100px;
				  display: block;
				}
				
				::v-deep .el-upload__tip {
				  // padding-top: 64px;
				  margin-left: 8px;
				  line-height: 16px;
				  color: @headerSelectTextColor;
				}

				.button-finish {
					display: flex;
					// justify-content: center;
					padding-left: 71px;

					::v-deep .el-button {
						width: 140px;
						height: 38px;
						background: #3370FF;
						border-radius: 6px;
					}

				}

			}
		}
	}
</style>
